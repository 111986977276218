<template>
  <div>
    <v-card class="elevation-0 transparent rounded-lg" :disabled="loading">
      <v-toolbar
        color="white"
        class="mb-3 rounded-lg"
        style="box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05)"
      >
        <v-text-field
          v-model="search"
          clearable
          flat
          dense
          single-line
          solo
          hide-details
          prepend-inner-icon="mdi-magnify"
          :label="$t('MF.search')"
          style="width: 100%"
        ></v-text-field>
        <v-spacer></v-spacer>

        <v-btn
          @click="
            openside = true;
            showNewBroadCast = true;
          "
          class="mx-2"
          color="primary"
          elevation="0"
          rounded
        >
          <v-icon left dark class="mr-3"> mdi-plus </v-icon>Send Broadcast
        </v-btn>
        <v-btn
          @click="
            openside = true;
            showNewNotify = true;
          "
          color="primary"
          elevation="0"
          rounded
        >
          <v-icon left dark class="mr-3"> mdi-plus </v-icon>Send Feed Update
        </v-btn>
      </v-toolbar>
    </v-card>

    <v-navigation-drawer
      v-model="openside"
      app
      temporary
      width="40vw"
      style="border-radius: 0"
      :right="$root.lang != 'ar'"
    >
      <v-toolbar
        color="rgb(238, 240, 248)"
        style="box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px"
        height="60"
      >
        <v-btn
          icon
          dark
          color="primary"
          @click="
            openside = false;
            showNewBroadCast = false;
            showNewNotify = false;
          "
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="text-primary">
          Send New Notification
        </v-toolbar-title>
      </v-toolbar>
      <v-card
        style="height: calc(100vh - 70px)"
        class="pa-3 flex-column justify-space-between"
        flat
        v-if="showNewBroadCast"
      >
        <div class="flex-grow-1">
          <v-text-field
            outlined
            v-model="msg.title"
            label="Title"
            hide-details
            class="mb-2"
          />
          <v-textarea
            rows="3"
            outlined
            v-model="msg.body"
            label="Content"
            hide-details
            class="mb-2"
          />
        </div>
        <div>
          <v-btn
            @click="sendBroadcast"
            :disabled="!(msg.title && msg.body)"
            block
            large
            rounded
            color="primary"
          >
            Send
          </v-btn>
        </div>
      </v-card>
      <v-card
        style="height: calc(100vh - 70px)"
        class="pa-3 d-flex flex-column justify-space-between"
        flat
        v-else-if="showNewNotify"
      >
        <div class="flex-grow-1">
          <v-combobox
            v-model="selectedItem"
            :items="items"
            :label="'Select Feed Item'"
            outlined
            hide-details
            item-text="name"
            item-value="id"
            hide-selected
            class="mb-2"
          >
            <template v-slot:selection="{ item }">
              [{{ item.type }}] - {{ item.title }}
            </template>
            <template v-slot:item="{ item }">
              [{{ item.type }}] - {{ item.title }}
            </template>
          </v-combobox>
          <v-combobox
            v-if="selectedItem != null && selectedItem.translation.length > 1"
            v-model="selectedLang"
            :items="langs"
            :label="'Select language'"
            outlined
            hide-details
            item-text="name"
            item-value="id"
            hide-selected
            class="mb-2"
          >
            <template v-slot:selection="{ item }">
              {{ item.name }}
            </template>
            <template v-slot:item="{ item }">
              {{ item.name }}
            </template>
          </v-combobox>

          <v-text-field
            outlined
            v-model="msg.title"
            label="Title"
            hide-details
            class="mb-2"
          />
          <v-textarea
            rows="3"
            outlined
            v-model="msg.body"
            label="Content"
            hide-details
            class="mb-2"
          />
        </div>
        <div>
          <v-btn
            @click="sendFeedUpdate"
            :disabled="!(msg.title && msg.body)"
            block
            large
            rounded
            color="primary"
          >
            Send
          </v-btn>
        </div>
      </v-card>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      addConsultation: false,
      search: "",
      showNewBroadCast: false,
      showNewNotify: false,
      openside: false,
      msg: {},
      topic: "",
      items: [],
      loading: true,
      selectedItem: null,
      langs: [],
      selectedLang: null,
      alllangs: this.$util.getLanguages()
    };
  },
  created() {
    this.$root.title = "Send Notification";
    this.$root.currentpage = "notify";
  },
  methods: {
    async getFeedItems() {
      await this.$feed
        .getFeedItems(
          this.$user.getCurrentUser().role.id == 3
            ? this.$user.getCurrentUser().id
            : null,
          -1
        )
        .then(data => {
          this.loading = false;
          console.log("items are", data);
          this.items = data;
        });
    },

    async sendFeedUpdate() {
      this.msg.itemid = this.selectedItem.id;
      this.msg.topic =
        process.env.NODE_ENV === "production" ? "feed" : "feed-test";
      this.$notify.feedNotify(this.msg);
      this.close();
    },
    async sendBroadcast() {
      this.msg.topic =
        process.env.NODE_ENV === "production" ? "broadcast" : "update-test";
      this.$notify.broadcastNotify(this.msg);
      this.close();
    },
    close() {
      this.msg = {};
      this.selectedItem = null;
      this.selectedLang = null;
    }
  },
  watch: {
    selectedItem(updated) {
      this.msg = {};
      this.langs = [];
      // console.log("updated",updated)
      this.langs = updated.translation.map(t => {
        return this.alllangs.find(l => {
          return t.lang == l.code;
        });
      });
      if (this.langs.length === 1) {
        this.msg.title = this.selectedItem.translation[0].title;
        this.msg.body = this.selectedItem.translation[0].summery;
      }
      this.$forceUpdate;
    },
    selectedLang(updated) {
      if (updated) {
        this.msg.title = this.selectedItem.translation.find(t => {
          return t.lang === updated.code;
        }).title;
        this.msg.body = this.selectedItem.translation.find(t => {
          return t.lang === updated.code;
        }).summery;
      }
    }
  },
  mounted() {
    this.getFeedItems();
    // console.log("all langs",this.alllangs)
  }
};
</script>

<style></style>
